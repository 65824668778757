export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const LOGOUT = 'LOGOUT';

export const SET_SIGNUP_MODAL = 'SET_SIGNUP_MODAL';
export const SET_LOGIN_MODAL = 'SET_LOGIN_MODAL';

export const SET_SCREEN_VIEW = 'SET_SCREEN_VIEW';

export const SET_BOOKING_REDIRECTION = 'SET_BOOKING_REDIRECTION';

export const GooglePlacesLocation = { country: "ae" }