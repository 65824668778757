import React from "react";
import { Box, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import GroupIcon from "@mui/icons-material/Group";
import {
  MessageInput,
  MessageList,
  Chat as PubNubChat,
} from "@pubnub/react-chat-components";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import emojiData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import PageTitleHeader from "../../Components/Header/PageTitleHeader";
import MessageCard from "../../Components/MessageCard";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00b894",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const useStyles = makeStyles({
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#F0F3F7",
    paddingBottom: "35px",
    minHeight: "100vh"
  },
  messageContainer: {
    width: "100%",
    overflowY: "auto",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
  },
  inputContainer: {
    position: "fixed",
    bottom: "0",
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  message: {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "8px",
    backgroundColor: "#f1f1f1",
  },
  sender: {
    fontWeight: "bold",
  },
});

function Chat() {
  const classes = useStyles();
  const { channel } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();;

  const gotoMemberList = () => {
    navigate(`/chat/${channel}/members`, { state: { members: state?.channelData?.players } });
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitleHeader
        title={state?.channelData?.pitch?.pitchName || "Chat"}
        rightButton={
          <IconButton onClick={gotoMemberList} aria-label="users list">
            <GroupIcon />
          </IconButton>
        }
      />
      <PubNubChat currentChannel={channel}>
        <Box className={classes.chatContainer}>
          <MessageList
            fetchMessages={50}
            enableReactions
            reactionsPicker={<Picker data={emojiData} />}
            messageRenderer={(item) => (
              <MessageCard
                item={item}
                isOwn={item?.isOwn}
                message={
                  item?.message.message?.text ||
                  item?.message.message?.message?.text
                }
                file={item?.message.message?.file}
                reactions={item?.message?.actions?.reaction}
                user={state.channelData.players.find(
                  (user) => user?.player?._id === item?.message?.uuid
                )?.player}
                time={item?.time}
              />
            )}
          />
        </Box>
        <Box className={classes.inputContainer}>
          <MessageInput
            typingIndicator
            fileUpload="image"
            emojiPicker={<Picker data={emojiData} />}
          />
        </Box>
      </PubNubChat>
    </ThemeProvider>
  );
}

export default Chat;
