import React from 'react';
import { Box, Typography, Button, Container, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PageTitleHeader from '../../Components/Header/PageTitleHeader';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function VerifiedSuccessfully() {
    const navigate = useNavigate();

    return (
        <Box sx={{ bgcolor: 'background.paper', minHeight: '100vh' }}>
            <PageTitleHeader
                title="Player Verified"
                onClick={() => navigate(-2)}
            />
            <Box sx={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', p: 3 }}>
                <Container maxWidth="sm">
                    <IconButton color="success" sx={{ fontSize: 80, mb: 2 }}>
                        <CheckCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Verification Successful!
                    </Typography>
                    <Typography variant="body1" color="text.secondary" paragraph>
                        The player has been successfully verified.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}
