const getAverageRating = (ratings) => {
    let total = 0;
    ratings?.forEach(item => {
        total += item.rate;
    })
    let avg = total / ratings?.length;
    if (isNaN(avg)) {
        return 0;
    }
    return avg.toFixed(1);
}

async function GetAddressByCoordinates({ lat, lon }) {
    let geocoder = new window.google.maps.Geocoder();

    let response = await geocoder.geocode(
        {
            location: {
                lat,
                lng: lon
            }
        },
    )
    if (response?.results[0]) {
        return response?.results[0]?.formatted_address?.split(",")?.splice(0, 4)?.join(",");
    } else {
        return "---";
    }
}

function camelCaseToSentenceCase(str) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); });
}

function trimString(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
}

export { getAverageRating, GetAddressByCoordinates, camelCaseToSentenceCase,trimString };