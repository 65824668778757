import React, {useState} from 'react'
import { Box, Divider, Typography, Input, Button, ThemeProvider, createTheme } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom';

import QRCodeScanner from '../../Components/QRCodeScanner'
import PageTitleHeader from '../../Components/Header/PageTitleHeader';
import { ScanPlayerByQR, ScanPlayerByTicketId } from '../../Api/services/referee';

export default function ScanPlayer() {

    const theme = createTheme({
        palette: {
            primary: {
                main: '#00b894',
            },
            secondary: {
                main: '#ffffff',
            },
        },
    });

    const navigate = useNavigate();
    const params = useParams();

    const [ticketId, setTicketId] = useState('');

    const onNewScanResult = async (decodedText, decodedResult) => {
        if (decodedText) {
            await ScanPlayerByQR({
                "bookingId": params?.booking,
                "playerId": params?.player,
            });
            navigate('/referee/verified');
        }
    };

    const verifyByTicketId = async () => {
        await ScanPlayerByTicketId({
            "bookingId": params?.booking,
            "ticketId": ticketId,
        });
        navigate('/referee/verified');
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.paper', minHeight: '100vh' }}>
                <PageTitleHeader
                    title="Scan Player"
                    onClick={() => navigate(-1)}
                />

                <QRCodeScanner
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />

                <Divider sx={{ mt: 2 }} />
                <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                    OR
                </Typography>
                <Divider sx={{ mt: 2 }} />

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Typography variant="body1" sx={{ mr: 1 }}>
                        Enter Ticket ID Manually
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                    <Input
                        placeholder="Enter Ticket ID here"
                        sx={{ width: '60%', mr: 1 }}
                        value={ticketId}
                        onChange={(e) => setTicketId(e.target.value)}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        sx={{ height: '100%', color: '#ffffff' }}
                        onClick={verifyByTicketId}
                    >
                        Verify
                    </Button>
                </Box>

            </Box>
        </ThemeProvider>
    )
}
