import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";

import Navigation from "./Router";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { SAVE_USER_INFO } from "./Constants";
import "react-tooltip/dist/react-tooltip.css";
import { getPlayerById } from "./Api/services/players";
import ScrollToTop from "./hooks/ScrollToTop";
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const getUserByID = async () => {
    let localUser = JSON.parse(localStorage.getItem("user"));

    if (localUser && !user?.uid) {
      let uid = localUser?.socialAuth ? localUser?.uid : localUser?.uid;
      let data = await getPlayerById(uid);
      dispatch({ type: SAVE_USER_INFO, payload: data });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUserByID();
    }

    const { host, pathname } = window.location;
    if (host === "kicks.co" && pathname !== "/landing") {
      window.location.href = "/landing";
    }
  }, []);

  return (
    <>
      <BrowserRouter>
      <GoogleOAuthProvider clientId="282837265984-qd7qjnihqhuntjcbvafpub2o1k1pe3s0.apps.googleusercontent.com">
        <ToastContainer />
        <Navigation />
        </GoogleOAuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
