import React, { useEffect, useState } from "react";
import {
  Typography,
  TableContainer,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import { CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from '@coreui/react'
import {
  SportsSoccer as GoalIcon,
  Warning as YellowCardIcon,
  Block as RedCardIcon,
  Close as CloseIcon,
  Help as AssistIcon,
} from "@mui/icons-material";
import { banPlayer } from "../../Api/services/match";
import { toast } from "react-toastify";
import { camelCaseToSentenceCase } from "../../Utils";
import { useParams } from "react-router-dom";
import { recordRedCard, recordScore, recordYellowCard } from "../../Api/services/referee";
import dayjs from "dayjs";

const ScoreBoard = ({
  players,
  bookingData,
  setNeedsRefetch,
}) => {
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [actionOpen, setActionOpen] = useState(false);
  const [formData, setFormData] = useState({
    reason: "",
    until: "",
    award: "",
    cardType: "",
  });

  const [formattedData, setFormattedData] = useState([]);

  function formatScoreboardData(data) {
    if (!data || !Array.isArray(data.players) || !Array.isArray(data.referee)) {
      return [];
    }

    const formattedData = data.players.map(player => {
      const playerStats = {
        playerId: player.player?._id || null,
        playerName: player.player?.name || "Unknown",
        goals: 0,
        assists: 0,
        awards: [],
        cards: [],
        bans: []
      };

      const actions = data.referee[0]?.actions || {};

      (actions.scores || []).forEach(score => {
        if (score.player === player.player?._id) {
          playerStats.goals = score.goals || 0;
          playerStats.assists = score.assists || 0;
        }
      });

      (actions.awards || []).forEach(award => {
        if (award.player === player.player?._id) {
          playerStats.awards.push({
            award: award.award || "Unknown",
            time: award.time || "Unknown"
          });
        }
      });

      (actions.cards || []).forEach(card => {
        if (card.player === player.player?._id) {
          playerStats.cards.push({
            type: card.type || "Unknown",
            time: card.time || "Unknown"
          });
        }
      });

      (actions.bans || []).forEach(ban => {
        if (ban.player === player.player?._id) {
          playerStats.bans.push({
            reason: ban.reason || "Unknown",
            until: ban.until || "Unknown",
            time: ban.time || "Unknown"
          });
        }
      });

      return playerStats;
    });

    return formattedData;
  }

  useEffect(() => {
    setFormattedData(formatScoreboardData(bookingData));
  }, [bookingData]);

  // Handle row selection
  const handleRowClick = (playerData) => {
    setSelectedPlayer(playerData);
  };

  // Handle action button clicks
  const handleActionClick = (type) => {
    setActionType(type);
    setActionOpen(true);
  };

  // Handle dialog close
  const handleClose = () => {
    setActionOpen(false);
    setActionType(null);
    setFormData({
      reason: "",
      until: "",
      award: "",
      cardType: "",
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const { id: bookingId } = useParams();

  // Submit Handlers
  const handleSubmit = async () => {
    if (!selectedPlayer) return;
    try {
      if (actionType === "goal") {
        const response = await recordScore(
          {
            "playerId": selectedPlayer?.playerId,
            "bookingId": bookingId,
            "goals": 1,
            "assists": 0,
          }
        );
        toast.success(response.message);
      } else if (actionType === "assist") {
        const response = await recordScore(
          {
            "playerId": selectedPlayer?.playerId,
            "bookingId": bookingId,
            "goals": 0,
            "assists": 1,
          }
        );

        toast.success(response.message);
      } else if (actionType === "redCard") {
        const response = await recordRedCard(
          {
            "playerId": selectedPlayer?.playerId,
            "bookingId": bookingId,
            "reason": "Unknown",
            "until": dayjs().add(14, "day").toISOString(),
          }
        );

        toast.success(response.message);
      } else if (actionType === "yellowCard") {
        if (selectedPlayer?.cards?.length === 0) {
          const response = await recordYellowCard(
            {
              "playerId": selectedPlayer?.playerId,
              "bookingId": bookingId,
              "type": "yellow"
            }
          );
          toast.success(response.message);
        } else {
          const response = await recordRedCard(
            {
              "playerId": selectedPlayer?.playerId,
              "bookingId": bookingId,
              "reason": "Unknown",
              "until": dayjs().add(14, "day").toISOString(),
            }
          );

          toast.success(response.message);
        }
      }
      setNeedsRefetch(true);
      handleClose();
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  return (
    <div>
      <TableContainer style={{ marginTop: "20px", paddingBottom: '55px' }}>
        <CTable>
          <CTableHead style={{ backgroundColor: "#00B894", color: "#F9F9F9" }}>
            <CTableRow style={{ fontSize: '14px' }}>
              <CTableHeaderCell scope="col">Player</CTableHeaderCell>
              <CTableHeaderCell scope="col">Goals</CTableHeaderCell>
              <CTableHeaderCell scope="col">Assists</CTableHeaderCell>
              <CTableHeaderCell scope="col">Awards</CTableHeaderCell>
              <CTableHeaderCell scope="col">Yellow</CTableHeaderCell>
              <CTableHeaderCell scope="col">Red</CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody style={{ fontSize: '14px' }}>
            {formattedData.map((playerData, index) => {
              return (
                <CTableRow
                  key={index}
                  hover
                  selected={selectedPlayer?.playerId === playerData.playerId}
                  onClick={() => playerData?.bans?.length === 0 && handleRowClick(playerData)}
                  style={{ cursor: "pointer", backgroundColor: selectedPlayer?.playerId === playerData.playerId ? "#a3ffec" : playerData?.bans?.length > 0 ? "#ff7675" : "inherit" }}
                >
                  <CTableDataCell component="th" scope="row">
                    {playerData.playerName || "Unknown"}
                  </CTableDataCell>
                  <CTableDataCell align="right">{playerData?.goals || 0}</CTableDataCell>
                  <CTableDataCell align="right">{playerData?.assists || 0}</CTableDataCell>
                  <CTableDataCell align="right">{playerData?.awards?.length > 0 ? playerData?.awards[0]?.award : "none"}</CTableDataCell>
                  <CTableDataCell align="right">{playerData?.cards?.length}</CTableDataCell>
                  <CTableDataCell align="right" color="error">{playerData?.bans?.length}</CTableDataCell>
                </CTableRow>
              );
            })}
          </CTableBody>
        </CTable>
      </TableContainer>

      {/* Bottom Action Bar */}
      {selectedPlayer && (
        <BottomNavigation
          showLabels
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            borderTop: "1px solid #ddd",
            backgroundColor: "#fff",
          }}
        >
          <BottomNavigationAction
            label="Goal"
            icon={<GoalIcon htmlColor="#00B894" />}
            onClick={() => handleActionClick("goal")}
          />
          <BottomNavigationAction
            label="Assist"
            icon={<AssistIcon color="info" />}
            onClick={() => handleActionClick("assist")}
          />
          <BottomNavigationAction
            label="Yellow Card"
            icon={<YellowCardIcon color="warning" />}
            onClick={() => handleActionClick("yellowCard")}
          />
          <BottomNavigationAction
            label="Red Card"
            icon={<RedCardIcon color="error" />}
            onClick={() => handleActionClick("redCard")}
          />
        </BottomNavigation>
      )}

      {/* Action Dialog */}
      <Dialog open={actionOpen} onClose={handleClose} fullWidth>
        <DialogTitle style={{ fontSize: "16px", backgroundColor: "#00B894", color: '#fff' }}>
          {actionType === "ban"
            ? `Ban ${selectedPlayer?.player?.name}`
            : actionType === "award"
              ? `Award ${selectedPlayer?.player?.name}`
              : actionType === "card"
                ? `Card ${selectedPlayer?.player?.name}`
                : actionType === "goal"
                  ? `Record Goal for ${selectedPlayer?.player?.name}`
                  : actionType === "assist"
                    ? `Record Assist for ${selectedPlayer?.player?.name}`
                    : actionType === "yellowCard"
                      ? `Record Yellow Card for ${selectedPlayer?.player?.name}`
                      : actionType === "redCard"
                        ? `Record Red Card for ${selectedPlayer?.player?.name}`
                        : ""}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {actionType === "ban" && (
            <>
              <TextField
                label="Reason"
                name="reason"
                value={formData.reason}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Until"
                name="until"
                type="datetime-local"
                value={formData.until}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </>
          )}
          {actionType === "award" && (
            <TextField
              label="Award"
              name="award"
              value={formData.award}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          )}
          {actionType === "card" && (
            <TextField
              label="Card Type"
              name="cardType"
              value={formData.cardType}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              select
              SelectProps={{
                native: true,
              }}
            >
              <option value="">Select Card Type</option>
              <option value="Yellow">Yellow</option>
              <option value="Red">Red</option>
            </TextField>
          )}
          {["goal", "assist", "yellowCard", "redCard"].includes(actionType) && (
            // Add any additional inputs if needed
            <Typography>
              Confirm recording {camelCaseToSentenceCase(actionType)} for{" "}
              {selectedPlayer?.player?.name || "Player"}.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{ color: "#00B894" }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" sx={{ background: "#00B894" }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScoreBoard;
