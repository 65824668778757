import { SET_BOOKING_REDIRECTION } from "../../Constants";

export const BookingReducer = (state = { shouldRedirect: false }, action) => {
  switch (action.type) {
    case SET_BOOKING_REDIRECTION:
      return { ...action.payload };
    default:
      return state;
  }
};
